//-> Color sytem
$blue: #5E50F9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #E91E63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray : #2e383e;
$dodger-blue : #3498db;

$colors: (
   blue: $blue,
   indigo: $indigo,
   purple: $purple,
   pink: $pink,
   red: $red,
   orange: $orange,
   yellow: $yellow,
   green: $green,
   teal: $teal,
   cyan: $cyan,
   white: $white,
   gray: #434a54,
   gray-light: #aab2bd,
   gray-lighter: #e8eff4,
   gray-lightest: #e6e9ed,
   gray-dark: #0f1531,
   black: #000000
);

$theme-colors: (
   primary: jdax-color(bgc-primary),
   secondary: jdax-color(bgc-secondary),
   success: #1bcf1b,
   info: #198ae3,
   warning: #fed713,
   danger: #ff0b34,
   light: #f8f9fa,
   dark: #3e4b5b
);

$theme-gradient-colors: (
   primary: jdax-color(bgc-gradient-primary),
   secondary: jdax-color(bgc-gradient-secondary),
   success: linear-gradient(to right, #84d9d2, #07cdae),
   info: linear-gradient(to right, #90caf9, #047edf 99%),
   warning: linear-gradient(to right, #f6e384, #ffd500),
   danger: linear-gradient(to right, #ffbf96, #fe7096),
   light: linear-gradient(to bottom, #f4f4f4, #e4e4e9),
   dark: linear-gradient(89deg, #a00303, #3e4b5b)
);
//<- Color system

//-> Social color
$social-colors: (
   twitter: #2caae1,
   facebook: #3b579d,
   google: #dc4a38,
   linkedin: #0177b5,
   pinterest: #cc2127,
   youtube: #e52d27,
   github: #333333,
   behance: #1769ff,
   dribbble: #ea4c89,
   reddit: #ff4500
);
//<- Social color

$type1-light: 'Raleway',
   sans-serif;
$type1-regular: 'Raleway',
   sans-serif;
$type1-medium: 'Raleway',
   sans-serif;
$type1-bold: 'Raleway',
   sans-serif;
$default-font-size: small;
//<- Fonts

//-> Sidebar
$sidebar-width-lg: 260px;
$sidebar-width-mini: 185px;
$sidebar-menu-font-size: $default-font-size;
$sidebar-menu-padding-y: 1.125rem;
$sidebar-menu-padding-x: 0.5rem;
$sidebar-submenu-font-size: .8125rem;
$sidebar-submenu-item-padding: .75rem; // 2rem .75rem 2rem
$sidebar-icon-font-size: 1.125rem;
$sidebar-arrow-font-size: .625rem;
$sidebar-profile-padding: .75rem 1.625rem .75rem 1.188rem;
$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;
$icon-only-collapse-width: 190px;
//<- Sidebar

//-> Navbar
$navbar-height: 175px; //SET TOP-MENU
$navbar-font-size: $default-font-size;
$navbar-icon-font-size: 1.25rem;
//<- Navbar

//-> Buttons
$button-fixed-width: 150px;
$btn-padding-y: .875rem;
$btn-padding-x: 2.5rem;
$btn-line-height: 1;
$btn-padding-y-xs: .5rem;
$btn-padding-x-xs: .75rem;
$btn-padding-y-sm: .50rem;
$btn-padding-x-sm: .81rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;
$btn-font-size: .875rem;
$btn-font-size-xs: .625rem;
$btn-font-size-sm: .875rem;
$btn-font-size-lg: .875rem;
$btn-border-radius: .1875rem;
$btn-border-radius-xs: .1875rem;
$btn-border-radius-sm: .1875rem;
$btn-border-radius-lg: .1875rem;
//<- Buttons

//-> Tooltip variables
//default styles
$tooltip-font-size: .75rem;
$tooltip-padding-y: .4rem;
$tooltip-padding-x: .75rem;
$tooltip-border-radius: .375rem;
//<- Tooltip variables

//-> Form
$input-border-radius: 2px;
$input-font-size: .8125rem;
$input-padding-y: .875rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;
$input-padding-y-xs: .5rem;
$input-padding-x-xs: .75rem;
$input-padding-y-sm: .50rem;
$input-padding-x-sm: .81rem;
$input-padding-y-lg: .94rem;
$input-padding-x-lg: 1.94rem;
//<- Form

//-> Tables
$table-cell-padding: .9375rem;
//<- Tables

//-> Measurement and property variables
$card-spacing-y: 2.5rem;
$card-padding-y: 2.5rem;
$card-padding-x: 2.5rem;
$card-border-radius: .3125rem;
$grid-gutter-width: 40px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
//<- Measurement and property variables

//-> Bread crumbs variables
// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: .5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-divider: "/";

// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
//<- Bread crumbs variables

//-> Modals variables
$modal-inner-padding: .937rem;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-backdrop-opacity: .5;
$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;
$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;
$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;
$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;
$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform .4s ease;
//<- Modals variables

//-> Vendor file asset path
$mdi-font-path: "~@mdi/font/fonts";

.logo {
   background: var(--c-background-image);
   width: jdax-size(sidebar-width-logo);
   height: jdax-size(sidebar-height-logo);
   background-size: cover;
   background-position: center center;
   margin-left: auto;
   margin-right: auto;
   margin-top: 2px;
}

.logo-mini {
   background: var(--c-background-image);
   width: jdax-size(sidebar-width-logo-mini);
   height: jdax-size(sidebar-height-logo-mini);
   background-size: cover;
   background-position: center center;
   margin-left: 5px;
   margin-right: auto;
   margin-top: 5px;
}