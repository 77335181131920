@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;
    /* Firefox 48 – 96 */
    print-color-adjust: exact !important;
    /* Firefox 97+, Safari 15.4+ */
  }

  @page {
    size: A4;
    margin: 8mm;
  }

  .noPrint {
    display: none !important;
  }

  section {
    display: grid;
    margin-block-end: 1.5rem;
    height: 100%;
    zoom: 75%;
  }

  .breakingSection {
    page-break-before: always;
    break-before: page;
    zoom: 50%;
  }

  .matchScoreRate {
    display: inline-flex;
    vertical-align: middle;
  }

  .matchScore {
    margin-top: 6px;
  }

  .printHeader {
    font-size: 40px;
  }

  .matIconColorForPrint {
    color: #000000 !important;
    font-size: 32px;
  }

  .matIconColorInvertedForPrint {
    color: #FFFFFF !important;
    font-size: 32px;
  }
}

//ngx-timepicker
.ngx-timepicker-control__input {
  color: #ffffff !important;
  background-color: #000000;
  border: 1px solid #ffffff;
}

.ngx-timepicker-control__input:focus {
  box-shadow: 0 0 0 0.2rem jdax-color(fc-border);
}

.ngx-material-timepicker-toggle {
  background-color: #FFFFFF !important;
}

.ngx-timepicker-control__arrow[_ngcontent-serverApp-c110] {
  color: #ffffff;
}

::ng-deep ngx-material-timepicker-content {
  --body-background-color: #FFFFFF;
  --button-color: #bf0303 !important;
  --dial-active-color: #fff;
  --dial-inactive-color: rgba(255, 255, 255, .5);
  --dial-background-color: #bf0303 !important;
  --dial-editable-active-color: #bf030396 !important;
  --dial-editable-background-color: #fff;
  --clock-face-time-active-color: #fff;
  --clock-face-time-inactive-color: #6c6c6c;
  --clock-face-inner-time-inactive-color: #929292;
  --clock-face-time-disabled-color: #c5c5c5;
  --clock-face-background-color: #f0f0f0;
  --clock-hand-color: #bf030396 !important;
}

// .timepicker {
//   .timepicker-dial__time {
//      display: block !important;
//   }
// }

// .timepicker__header {
//    background-color: #ffffff !important;
// }

// .clock-face__clock-hand {
//    background-color: #ffffff !important;

//    &::after {
//       background-color: #ffffff !important;
//    }

//    &::before {
//       border-color: #ffffff !important;
//    }
// }

// .clock-face__number {
//    >span.active {
//       background-color: #ffffff !important;
//    }
// }

// button:not(.timepicker-dial__item) {
//    color: #ffffff !important;
// }

//mat-table
/* General */
.mat-table {
  background: transparent;

  .mat-row {
    background: linear-gradient(45deg, #564642 0%, #000000 33%, #312d2d 66%, #564642 100%);
    -webkit-box-shadow: 1px 1px 1px 1px jdax-color(bgc-secondary);
    box-shadow: 1px 1px 1px 1px jdax-color(bgc-secondary);
  }

  .mat-cell {
    &.m-card-sub-title {
      color: jdax-color(fc-contentDatagrid-primary);
    }
  }
}

.matMainHeader {
  background-color: jdax-color(bgc-contentDatagridMobile-primary);
}

.matHeader {
  background-color: #f1efefa3;
  color: #212121;
  border-radius: 7px;
  border: solid 2px #000000;
  padding: 10px;
  white-space: nowrap;

  @media screen and (max-width: 991px) {
    padding: 3px;
    font-size: xx-small;
  }

  @media print {
    background-color: #cacaca;
    font-size: x-large;
    border-radius: 0px;
  }
}

.matCell {
  white-space: nowrap;
  background-color: #202020;
  color: #FFFFFF;
  //border-radius: 7px;
  border: solid 2px #000000;
  padding: 10px;
  text-align: left;

  @media screen and (max-width: 991px) {
    padding: 2px;
    font-size: xx-small;
    font-stretch: condensed;
  }

  @media print {
    background: none;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 0px;
    font-size: x-large;
    padding: 5px;
  }
}

.matTable {
  width: 100%;
  font-size: medium;

  @media screen and (max-width: 991px) {
    font-size: x-small;
  }

  @media print {
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 0px;
    font-size: x-large;
    padding: 5px;
  }
}

/* Header row */
.jdax-datagrid-header-row>.mat-header-cell {
  color: jdax-color(fc-headerDatagrid-primary) !important;
  background-color: jdax-color(bgc-contentDatagridMobile-primary);
}

/* Row highlight */
.jdax-datagrid-highlight-row:hover {
  background-color: jdax-color(bgc-highlight);
  color: jdax-color(fc-highlight);
}

.hide-arrow .mat-sort-header-arrow {
  color: jdax-color(fc-content-secondary);
}

.mat-sort-header-arrow {
  color: jdax-color(fc-content-secondary);
}

.jdax-datagrid-cellWithoutValue {
  border: 1px solid jdax-color(fc-borderDatagrid);
}

.mat-row,
.mat-header-row,
.mat-footer-row {
  border: 1px solid jdax-color(fc-borderDatagrid);
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border: 1px solid jdax-color(fc-borderDatagrid);
}

@media screen and (max-width: 991px) {
  .jdax-datagrid-cellWithoutValue {
    border: 0px solid jdax-color(fc-borderDatagrid);
  }

  .mat-header-row,
  .mat-footer-row {
    border: 0px solid jdax-color(fc-borderDatagrid);
  }

  th.mat-header-cell,
  td.mat-footer-cell {
    border: 0px solid jdax-color(fc-borderDatagrid);
  }

  td.mat-cell {
    //border: 0px solid #000000;
  }

  .mat-elevation-z8 {
    background: transparent;
  }

  .mat-table {
    background: transparent;

    .mat-row {
      background: linear-gradient(45deg, #564642 0%, #000000 33%, #312d2d 66%, #564642 100%);
      -webkit-box-shadow: 1px 1px 1px 1px jdax-color(bgc-secondary);
      box-shadow: 1px 1px 1px 1px jdax-color(bgc-secondary);
    }

    .mat-cell {
      &:first-child {
        a {
          color: inherit;
        }
      }

      &.m-card-sub-title {
        color: jdax-color(fc-contentDatagridMobile-primary);
      }
    }
  }
}

.mat-paginator-container {
  background-color: #FFFFFF9A !important;
}

//mat-tab

.mat-tab-header-pagination-chevron {
  border-color: #FFFFFF;
}

::ng-deep .mat-tab-label {
  color: #FFFFFF !important;
  background-color: jdax-color(bgc-primary);

  @media screen and (max-width: 991px) {
    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  }
}

.mat-tab-link,
.mat-tab-label-content {
  color: #FFFFFF !important;
}

.mat-tab-group {
  background-color: jdax-color(bgc-primary-fade);
}

::ng-deep .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  @media screen and (max-width: 991px) {
    background-color: rgba(255, 255, 255, 0.603) !important;
  }
}

//mat-select

.mat-select {
  background-color: #000000;
  color: #FFFFFF;
}

.mat-select-arrow {
  color: #FFFFFF;
}

.mat-select-placeholder {
  color: #999999;
}

.mat-select-value-text {
  color: #FFFFFF;
}

//mat-input

.mat-form-field-label {
  color: #FFFFFF !important;
}

.mat-input-element {
  color: #FFFFFF !important;
}

input::placeholder {
  color: #999999 !important;
}

input::-webkit-input-placeholder { /* Edge */
  color: #999999 !important;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999999 !important;
}

input:-moz-placeholder {
  color: #999999 !important;
}

input::-moz-placeholder {
  color: #999999 !important;
}

//mat-expansion

.mat-expansion-panel-header-title {
  color: #FFFFFF !important;
}

.mat-expansion-panel {
  background-color: #000000be;
  color: #FFFFFF;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background-color: transparent;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: #FFFFFF;
}

//mat-checkbox

.mat-checkbox-frame {
  border-color: #FFFFFF;
}

.mat-checkbox {
  background-color: transparent !important;
  color: #FFFFFF;
}

//mat-card

.mat-card-tooltip-color {
  background: #013880fa !important;
  border: 1px solid rgba(255, 255, 255, 0.925) !important;
}

.mat-card {
  background-color: rgba(0, 0, 0, 0.60);
  border: 1px solid rgba(85, 83, 53, 0.25);
  color: #FFFFFF;

  @media print {
    background-color: #FFFFFF;
    color: #000000;
    border: 0px solid;
  }
}

.mat-card-color {
  background-color: jdax-color(bgc-modalForm-primary);
  border: 1px solid rgba(255, 255, 255, 0.925);
  border-radius: 1px;
}

.matcard-title {
  background-color: rgba(0, 0, 0, 0.507);
  padding: 15px 30px;
  border-radius: 30px;
  color: #FFFFFF;

  @media print {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

.content-wrapper {
  @media print {
    background-color: #FFFFFF;
  }
}

.mat-card-subtitle {
  color: jdax-color(fc-data-important);
}

.mat-card-header-color {
  background-color: jdax-color(bgc-primary);
  background: linear-gradient(124deg, jdax-color(bgc-secondary) 0%, jdax-color(bgc-primary) 69%, jdax-color(bgc-primary) 100%);
  color: #FFFFFF;
  border-radius: 5px;

  @media print {
    background: none;
    background-color: jdax-color(bgc-secondary);
  }
}

.matcard>mat-card {
  background-color: rgba(0, 0, 0, 0.507);
  color: #FFFFFF;
}

.mainmatcard>mat-card {
  background-color: rgba(0, 0, 0, 0.507);
}

// mat-accordion

// mat-accent

/* Button of tab */
.mat-accent {
  background-color: jdax-color(bgc-primary);
  color: jdax-color(fc-primary) !important;
}

//mat-button
.mat-button-toggle-checked {
  background-color: jdax-color(bgc-primary);
  color: jdax-color(fc-primary) !important;
}

.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled {
  background-color: rgb(95, 95, 95);
}

// css-timeline

.timeline:before {
  background-color: jdax-color(bgc-primary);
}

.timeline>li>.timeline-panel {
  border: 1px solid #564642;
  -webkit-box-shadow: 0 1px 6px #0000002d;
  box-shadow: 0 1px 6px #0000002d;
  background-color: #000000;
}

.timeline>li>.timeline-badge {
  color: #FFFFFF;
  background-color: #999999;
}

.timelineLabelBadge {
  color: #FFFFFF;
  background-color: #000000;
}

.timeline-badge.primary {
  background-color: #984747 !important; // jdax-color(bgc-primary)
}

.timeline-title {
  color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
  background: transparent;
}

.timeline>li>.timeline-panel:before {
  border-top: 15px solid transparent;
  border-left: 15px solid #564642;
  border-right: 0 solid #564642;
  border-bottom: 15px solid transparent;
}

.timeline>li>.timeline-panel:after {
  border-top: 15px solid transparent;
  border-left: 15px solid #564642;
  border-right: 0 solid #564642;
  border-bottom: 15px solid transparent;
}

// css-timeline activity
.timelineActivity:before {
  background-color: #984747;
}

.timelineActivity>li>ti>.timeline-panel {
  border: 1px solid #564642;
  -webkit-box-shadow: 0 1px 6px #0000002d;
  box-shadow: 0 1px 6px #0000002d;
  background-color: #000000;
}

.timelineActivity>li>ti>.timelineActivity-badge {
  color: #FFFFFF;
  background-color: #999999;
}

.timelineActivityLabelBadge {
  color: #FFFFFF;
  background-color: #000000;
}

.timelineActivity-badge.primary {
  background-color: #984747 !important;
}

.timeline-title {
  color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
  background: transparent;
}

.timelineActivity>li>ti>.timeline-panel:before {
  border-top: 15px solid transparent;
  border-left: 15px solid #564642;
  border-right: 0 solid #564642;
  border-bottom: 15px solid transparent;
}

.timelineActivity>li>ti>.timeline-panel:after {
  border-top: 15px solid transparent;
  border-left: 15px solid #564642;
  border-right: 0 solid #564642;
  border-bottom: 15px solid transparent;
}

///////////////////////////////////////////////// HTML ////////////////////////////////////////////////////
.buttonYes {
  background-color: jdax-color(bgc-data-ok) !important;
  width: 90px !important;

  @media screen and (max-width: 991px) {
    font-size: x-small !important;
    width: 60px !important;
  }
}

.buttonNo {
  background-color: jdax-color(bgc-data-ko) !important;
  width: 90px !important;

  @media screen and (max-width: 991px) {
    font-size: x-small !important;
    width: 60px !important;
  }
}

.buttonNormalNo {
  background-color: jdax-color(bgc-data-ko) !important;
}

.buttonNormalYes {
  background-color: jdax-color(bgc-data-ok) !important;
}

.buttonDelete {
  background-color: jdax-color(bgc-data-warning) !important;
}

.jdax-border {
  border: 1px solid jdax-color(bgc-primary);
}

.form-button {
  background-color: jdax-color(bgc-primary);
  color: jdax-color(fc-primary);
}

textarea.mat-input-element {
  background-color: #000000; // jdax-color(bgc-textarea);
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem jdax-color(fc-border);
  background-color: #000000;
}

hr {
  background-color: jdax-color(bgc-primary);
}

a:link {
  color: jdax-color(fc-link)
}

/* visited link */
a:visited {
  color: jdax-color(fc-link)
}

/* mouse over link */
a:hover {
  text-decoration: none;
  color: jdax-color(fc-link-hover)
}

/* selected link */
a:active {
  color: jdax-color(fc-link)
}

.auth-link {
  border-bottom: 1px solid jdax-color(fc-link);
  @extend .text-white;
}

.alink {
  color: jdax-color(fc-link) !important;
  border-bottom: 1px solid jdax-color(fc-link);
}

.linkButton {
  border: solid 0px;
  background-color: jdax-color(bgc-secondary);
  color: #FFFFFF;
}

.jdax-panel-round {
  -webkit-box-shadow: 0px 3px 10px 0px jdax-color(bgc-secondary);
  -moz-box-shadow: 0px 3px 10px 0px jdax-color(bgc-secondary);
  box-shadow: 0px 3px 10px 0px jdax-color(bgc-secondary);
  background-color: jdax-color(bgc-content-primary);
}

.jdax-panel-round-primary-flat {
  background-color: jdax-color(bgc-primary);
  color: jdax-color(fc-primary);
}

.jdax-panel-round-flat {
  background-color: jdax-color(bgc-content-primary);

  -webkit-box-shadow: 0px 3px 3px 0px jdax-color(bgc-secondary);
  -moz-box-shadow: 0px 3px 3px 0px jdax-color(bgc-secondary);
  box-shadow: 0px 3px 3px 0px jdax-color(bgc-secondary);
}

///////////////////////////////////////////////// HTML ////////////////////////////////////////////////////
.mat-dialog-container {
  background-color: #00000099 !important;
  box-shadow: none;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}