/* Animation Mixins */
@keyframes dropdownAnimation {
	from {
	  opacity: 0;
	  transform: translate3d(0, -30px, 0);
	}
  
	to {
	  opacity: 1;
	  transform: none;
	  transform: translate3d(0, 0px, 0);
	}
  }
  
  .dropdownAnimation {
	animation-name: dropdownAnimation;
	@include animation-duration($action-transition-duration);
	@include animation-fill-mode(both);
  }
  @mixin transition($settings) {
	-webkit-transition: $settings;
	-moz-transition: $settings;
	-ms-transition: $settings;
	-o-transition: $settings;
	transition: $settings;
  }
  @keyframes fadeOut {
	from {
	  opacity: 1;
	}
  
	to {
	  opacity: 0;
	}
  }
  
  .fadeOut {
	animation-name: fadeOut;
  }
  
  .infinite-spin {
	@keyframes spin {
	  from {
		transform: rotate(0deg);
	  }
  
	  to {
		transform: rotate(360deg);
	  }
	}
	animation-name: spin;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
  }
  @keyframes fadeInUp {
	from {
	  opacity: 0;
	  transform: translate3d(0, 100%, 0);
	}
  
	to {
	  opacity: 1;
	  transform: none;
	}
  }
  
  .fadeInUp {
	animation-name: fadeInUp;
  }

/* Miscellaneous Mixins */

// general transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	  -o-transform: $transforms;
	 -ms-transform: $transforms;
 -webkit-transform: $transforms;
	   transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
@include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
@include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
@include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
 moz-transform-origin: $origin;
	  -o-transform-origin: $origin;
	 -ms-transform-origin: $origin;
 -webkit-transform-origin: $origin;
	   transform-origin: $origin;
}
//Ellipsis
%ellipsor{
 text-overflow: ellipsis;
 overflow: hidden;
 max-width:100%;
 white-space: nowrap;
}

// Placeholder
@mixin placeholder {
 &::-webkit-input-placeholder {
	 @content
 }
 &:-moz-placeholder {
	 @content
 }
 &::-moz-placeholder {
	 @content
 }
 &:-ms-input-placeholder {
	 @content
 }
}