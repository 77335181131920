//-> Fonction
@function jdax-color($key: "bgc-primary") {
   @return map-get($jdax-colors, $key);
}

@function jdax-size($key) {
   @return map-get($jdax-sizes, $key);
}

//<- Fonction

//-> Logo
.LogoBackgroundColor {
   background-color: #FFFFFF;
}

//<- Logo

//-> JDAX Added colors
$jdax-colors: (
   bgc-primary: #bf0303,
   bgc-primary-fade: #bf030396,
   fc-primary: #ffffff,

   bgc-secondary: #000000,
   //header, footer
   fc-secondary: #ffffff,

   fc-link: #fd8e44e5,
   fc-link-hover: #fd8e44,

   bgc-modalForm-primary: #700101,

   bgc-headerDatagrid-primary: #000000,
   //Back color of header datagrid
   fc-headerDatagrid-primary: #ffffff,
   //Fore color of header datagrid
   fc-contentDatagridMobile-primary: #ffffff,
   //Fore color of header datagrid
   fc-contentDatagrid-primary: #ffffff,
   //Fore color of header datagrid
   bgc-contentDatagridMobile-primary: #564642,
   //Back color of row datagrid on mobile
   bgc-contentDatagrid-primary: #564642,
   //Back color of datagrid row
   fc-borderDatagrid: #dcdcdc40,
   //Fore color of border datagrid
   bgc-selectedDatagrid-primary: #c9c9c950,
   //Back color of datagrid selected row

   bgc-content-primary: #000000,
   //Back color of content
   fc-content-primary: #ffffff,
   //Fore color of content

   bgc-contentCard-primary: #f5f5f5,

   fc-title: #ffffff,
   //Fore color of title

   bgc-gradient-primary: linear-gradient(to right, #fbf1f1, #5a5a5a),
   bgc-gradient-secondary: linear-gradient(to right, #fbf1f1, #5a5a5a),
   fc-border: #5a5a5a,
   //Fore color of border

   bgc-highlight: #ddb5a5,
   //Back color of highlight item (datagrid row, menu, ...)
   fc-highlight: #ffffff,
   //Fore color of highlight item (datagrid row, menu, ...)
   


   fc-sidebar-menu: #ffffff,
   bgc-sidebar-menu-hover: #cc1e1e,
   fc-sidebar-menu-hover: #ffffff,
   fc-sidebar-menu-active: #f6fa20,
   bgc-sidebar-menu-active: #f44b4b,

   fc-navbar-loginTitle: #ffffff,
   fc-dropdown-arrowDown: #ffffff,
   bgc-navbar-searchTitle: #ffd0d0,
   bgc-navbar-searchTitleInput: #ffffff,

   fc-data-invalid: #ff0000,
   fc-data-important: #fd8e44,
   fc-data-warning: #ddbb25,
   bgc-data-warning: #ddbb25,
   bgc-data-disabled : #68686896,
   bgc-placeholderIcon: #333333,

   bgc-data-ok: #4caf4c,
   bgc-data-ko: #d14040,
   fc-data-ok: #65eb65,
   fc-data-ko: #d14040,

   fc-formPanel-border: #000000,
   bgc-formPanel: #00000099,

   bgc-textarea: #FFFFFF,
   fc-textarea-border : #fd8e44,
   fc-textarea-color : #000000,

   bgc-input-underline : #ffff006b,

   bgc-match-atHome: #163052,
   bgc-match-notAtHome: #185458,
   bgc-match-friendly: #501755,
   bgc-match-training: #00ff4c93,
   bgc-match-tournament: #552626,
   bgc-match-cup: #000000,
   bgc-match-closingDay: #636363,
   bgc-match-bye: #e2e2e2,

   fc-match-atHome: #306bb9,
   fc-match-notAtHome: #30b0b9,
   fc-match-friendly: #ad30b9,
   fc-match-training: #00ff4c,
   fc-match-tournament: #a54141,
   fc-match-cup: #888888ad,
   fc-match-closingDay: #FFFFFF,
   fc-match-bye: #4e4e4e,

   fc-match-notPresent : #ff0000,
   bgc-match-notPresent : #c03030,
   fc-match-present : #65eb65,
   bgc-match-present : #4caf4c,
   fc-match-guard : #ddbb25,
   bgc-match-guard : #ddbb25,
   bgc-match-tentative : #999999,

   fc-match-man: #306bb9,
   fc-match-woman: #da7989,
   fc-match-young: #b8b8b8

);
//<- JDAX Added colors

//-> JDAX Added size
$jdax-sizes: (
   sidebar-width-logo: 120px,
   sidebar-height-logo: 120px,
   sidebar-top-logo: 80px,
   sidebar-width-logo-mini: 75px,
   sidebar-height-logo-mini: 60px,
   sidebar-top-logo-mini: 0px
);
//<- JDAX Added size