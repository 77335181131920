/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

@import '../styles/variables.scss';

@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";

@import "../styles/theme.scss";

@import "../styles/bootstrap/bootstrap-variables.scss";
@import "../styles/bootstrap/bootstrap.scss";
@import "../styles/bootstrap/mixins/mixin.scss";

@import "../styles/jdax-theme/main.scss";
@import "../styles/jdax-theme/main-color.scss";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

