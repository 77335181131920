/* Bootstrap Alerts */

.alert {
  font-size: $default-font-size;
  padding: 0.4rem;
  width: 100%;
  i {
    font-size: 1.25rem;
    margin-right: 1.25rem;
    vertical-align: middle;
    //line-height: .5;
  }
}

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(rgba(theme-color($color), .2), theme-color-level($color, 1), theme-color-level($color, 3));
  }
}

@each $color, $value in $theme-colors {
  .alert-fill-#{$color} {
    @include alert-variant(theme-color($color), theme-color($color), color(white));
  }
}

// Functions

@function social-color($key: "twitter") {
  @return map-get($social-colors, $key);
}

@function theme-gradient-color($key: "primary") {
  @return map-get($theme-gradient-colors, $key);
}

.sidebar-icon-only {
  .main-panel {
     @media(min-width: 992px) {
        width: calc(100% - #{jdax-size(sidebar-width-logo)});
     }
  }
}

.icon-xxsmall {
  transform: scale(1.1);
}

/* Miscellanoeous */
body,
html {
   overflow-x: hidden;
   padding-right: 0 !important; // resets padding right added by Bootstrap modal
   overscroll-behavior-y: contain;
}

body {
   background-color: jdax-color(bgc-content-primary);
   
   @media print {
      background: none;
      background-color: #FFFFFF;
      font-size: medium;
      zoom: 66%;
    }
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
   overflow: auto;
}

.page-body-wrapper {
   min-height: calc(100vh - #{$navbar-height});
   padding-top: 0;
   @include display-flex();
   @include flex-direction(row);
   padding-left: 0;
   padding-right: 0;

   &.full-page-wrapper {
      width: 100%;
      min-height: 100vh;
   }
}

.load-page-body-wrapper {
   min-height: 100vh;
   @include display-flex();
   @include flex-direction(row);
   padding: 0px;
   width: 100%;
}

.main-panel {
   transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
   width: calc(100% - #{$sidebar-width-lg});
   min-height: calc(100vh - #{$navbar-height});
   @include display-flex();
   @include flex-direction(column);

   @media screen and (max-width: 991px) {
      margin-left: 0;
      width: 100%;
   }

   @media print {
      display: block;
      width: 100%;
      min-height: 100vh;
   }

   &.main-panel-only {
      transition: none;
   }
}

.content-wrapper {
   background-color: jdax-color(bgc-content-primary);
   padding: 0px; //Padding pour l'affichage des pages en mode normal

   @media screen and (max-width: 991px) {
      padding: 5px; //Padding pour l'affichage des pages en mode mobile
   }
   

   width: 100%;
   @include flex-grow(1);
}

.container-scroller {
   overflow: hidden;
}

pre {
   background: color(gray-lighter);
   padding: 15px;
   font-size: 14px;
}

code {
   padding: 5px;
   color: theme-color(danger);
   font-size: $default-font-size;
   border-radius: 4px;
}

.page-header {
   @extend .d-flex;
   @extend .justify-content-between;
   @extend .align-items-center;

   .breadcrumb {
      border: 0;
      margin-bottom: 0;
   }
}

.page-title {
   color: $black;

   margin-bottom: 0;

   .page-title-icon {
      display: inline-block;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      text-align: center;
      box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);

      i {
         font-size: .9375rem;
         line-height: 36px;
      }
   }
}

.purchase-popup {
   width: 100%;
   z-index: 9999;
   background: #fff;
   padding: 15px;
   display: flex;
   margin-bottom: 1rem;

   @include media-breakpoint-down(md) {
      padding: 15px 20px;
      display: block;
   }

   .btn {
      margin-right: 20px;
      font-weight: 500;

      &.purchase-button {
         color: #fff;
         border: none;
         line-height: 1;
         vertical-align: middle;
         min-height: auto;
      }
   }

   p {
      margin-bottom: auto;
      margin-top: auto;
      color: #1c273c;
      font-weight: 400;
      vertical-align: middle;
      line-height: 1;

      @include media-breakpoint-down(md) {
         margin-bottom: 10px;
      }
   }

   i {
      vertical-align: middle;
      line-height: 1;
      margin: auto 0;
      color: darken(#e4e4e4, 20%);
   }
}

.removeProbanner {
   #proBanner {
      display: none;
   }
}

@media screen and (max-width: 991px) {
  .navbar {
     .navbar-brand-wrapper {
        .brand-logo {
           display: none;
        }

        .brand-logo-mini {
           display: inline-block;
        }
     }
  }
}

@media screen and (max-width: 991px) {
  .row-offcanvas {
     position: relative;
     -webkit-transition: all .25s ease-out;
     -o-transition: all .25s ease-out;
     transition: all .25s ease-out;

     &.row-offcanvas-right {
        right: 0;

        ::ng-deep .sidebar-offcanvas {
           right: -100%; // 12 columns
        }

        &.active {
           right: calc(50% + 30px); // 6 columns

           ::ng-deep .sidebar-offcanvas {
              right: calc(-50% - 15px); // 6 columns
           }
        }
     }

     &.row-offcanvas-left {
        left: 0;

        ::ng-deep .sidebar-offcanvas {
           left: -100%; // 12 columns
        }

        &.active {
           left: 50%; // 6 columns

           ::ng-deep .sidebar-offcanvas {
              left: -50%; // 6 columns
           }
        }
     }

     ::ng-deep .sidebar-offcanvas {
        position: absolute;
        top: 0;
        width: calc(50% + 30px); // 6 columns
     }
  }
}

@keyframes spin {
  to {
     transform: rotate(360deg);
  }
}